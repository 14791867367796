<template>
  <v-app id="inspire" style="background: white">
    <v-row align="center" justify="center">
      <v-card class="login-card">
        <v-row class="form-container">
          <v-col>
            <v-card-text>
              <v-img :src="require('../../assets/logo.svg')" alt="logo-piximind">
              </v-img>
              <v-form v-model="isValid" @keyup.native.enter="forgotPassword">
                <TextInput id="email" label="Email" :rules="emailRules" v-model="email"/>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <Button action_name="Envoyer mot de passe !" :disabled="!isValid" :loading="loading" style="width: 100%" @clicked="forgotPassword" />
              <v-spacer />
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>
<script>
import { AuthApi } from "@/services/auth.api";
import { TextInput, Button } from "@/components/base";
const authService = new AuthApi();
export default {
  components: { TextInput, Button },
  data() {
    return {
      loading: false,
      snackbar: false,
      text: "",
      timeout: 2000,
      isValid: true,
      logged: false,
      model: null,
      email: "",
      error: false
    };
  },
  mounted() {
    if (localStorage.token) {
      this.logged = true;
      this.$router.push("/dashboard");
    }
  },
  methods: {
    forgotPassword() {
      this.loading = true;
      authService
        .forgotPassword(this.email)
        .then(
          response => {
            this.loading = false;
            this.text = response.data.message;
            this.snackbar = true;
          },
          error => {
            this.loading = false;
            this.text = error.response.data;
            this.snackbar = true;
          }
        )
        .catch(() => this.handleFail());
    },
    handleFail() {
      this.text = "Request failed!";
      this.snackbar = true;
    }
  }
};
</script>
<style scoped>
.v-card__actions {
  padding-right: 16px;
  padding-left: 16px;
}

.connect-btn {
  width: 100%;
}

/*.connect-btn{*/
/*  background: #79C300;*/
/*}*/
/*.connect-btn-text {*/
/*  color: white;*/
/*  font-family: "Segoe UI", serif;*/
/*  font-size: 20px;*/
/*  text-transform: none;*/
/*}*/

.login-card {
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0 !important;
}

.forgot-password {
  text-align: center;
}

.forgot-password a {
  text-decoration: none;
  color: #11887e;
  font-family: "Segoe UI", serif;
  font-size: 16px;
}

.form-container {
  padding: 30px;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  background: #86bc26 !important;
}

label {
  color: #707070;
  font-family: "Lato", serif;
}
</style>
